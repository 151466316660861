import React, { useContext } from 'react'
import { GlobalDataContext } from '../../../context/context'
import { ButtonContent } from '../boton/ButtonContent'
import Navmenu from './NavMenu'



const Header7 = () => {

    const { rpdata } = useContext(GlobalDataContext)

    return (
        <header className='absolute right-[3%] left-[3%] z-[15] bg-white px-4 lg:px-6 my-6 rounded-[80px]'>
            <div className='flex flex-row-reverse lg:flex-row'>
                <div className='w-full mr-0 md:w-[75%] lg:w-[30%] lg:mr-10 mt-0 lg:-mt-[30px] mb-0 lg:-mb-[50px] rounded-lg'>
                    <img
                        src={rpdata?.dbPrincipal?.logo}
                        alt='logo'
                        loading='lazy'
                        className='w-[70%] mx-auto pt-0 pb-5 -mb-16'
                    />
                </div>

                <div className='block w-[20%] lg:w-[75%] self-center'>
                    <div className='flex'>
                        <div className='w-[25%] lg:w-[75%] flex items-center justify-start'>
                            <nav>
                                <Navmenu />
                            </nav>
                        </div>
                        <div className=' hidden lg:block w-[45%] lg:w-[25%] text-end border-l ml-0 lg:ml-7'>
                            <ButtonContent />
                        </div>
                    </div>
                </div>

            </div>
        </header>
    )
}

export default Header7
